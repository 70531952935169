import React from "react"
import styles from './Team.module.css'
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { WithStore } from 'pure-react-carousel';
import MobileCaroussel from "./MobileCaroussel";
import { useEffect } from "react";
import ArrowRight from '../../assets/arrow_right.inline.svg'
import CarrousselNavigation from "./CarrousselNavigation";

const teamMembers = [
    {
        name: 'Quentin Kermaidic',
        skill: 'Back end',
    },
    {
        name: 'Rémi Seris',
        skill: 'Full Stack'
    },
    {
        name: 'Éric Amilhat',
        skill: 'Full Stack'
    },
    {
        name: 'Charly Pauillac',
        skill: 'Full Stack'
    },
    {
        name: 'Benjamin Roux',
        skill: 'Responsable Produit'
    },
    {
        name: 'Thomas Crecq',
        skill: 'Designer',
    },
]

const Member = ({ name, skill }) => {
    return <div className={styles.member}>
        <h3>
            {name}
        </h3>
        <div className={styles.skillContainer}>
            <div className={styles.line} />
            <div className={styles.skill} >
                {skill}
            </div>
        </div>
    </div>
}



const Team = () => {


    const matchMobile = typeof window !== "undefined" ? window.matchMedia("(max-width: 640px)").matches : false
    const matchIpad = typeof window !== "undefined" ? window.matchMedia("(max-width: 1024px)").matches : false

    return (
        <div className={styles.layout} style={{ paddingBottom: '88px' }}>
            <div className={`section`} >
                <SectionTitle title={"L'équipe"} className={styles.head} />
                <h2 className={styles.topLine}>
                    Une équipe composée d’<strong className="extended">experts </strong>
                    curieux, à l'esprit vif, affamés de projets innovants
                </h2>
                <div className={styles.head} style={{ gridColumn: "2 / span 8" }}>
                    {teamMembers.map((member) => <Member {...member} />)
                    }
                </div>
            </div>
            <div style={{ width: '100%' }}>
                {

                    // matchMobile ?
                    // <div style={{
                    //     width: '100%', positoin: 'absolute',
                    //     display: 'flex', justifyContent: 'center', overflowX: 'visible'
                    // }}>
                    //     <CarouselProvider

                    //         naturalSlideWidth={typeof window !== "undefined" ? window?.screen?.width : ""}
                    //         naturalSlideHeight={350}
                    //         totalSlides={teamMembers.length}
                    //         visibleSlides={1}
                    //     >
                    //         <Slider moveThreshold={.2} style={{ width: `${typeof window !== "undefined" ? window?.screen?.width : ''}px` }}>
                    //             {teamMembers.map((member, index) => <Slide index={index}> <MobileCaroussel index={index} member={member} /></Slide>)}
                    //         </Slider>
                    //         <div>
                    //             <CarrousselNavigation totalSlide={teamMembers.length} />
                    //         </div>
                    //     </CarouselProvider>
                    // </div>
                    // : matchIpad ?
                    // <div style={{ gridColumn: "4 / span 4", display: 'flex', justifyContent: 'center', overflowX: 'visible' }}>
                    //     <div style={{ width: '2000px' }}>

                    //         <CarouselProvider

                    //             naturalSlideWidth={400}
                    //             naturalSlideHeight={850}
                    //             totalSlides={teamMembers.length}
                    //             visibleSlides={3}
                    //         >
                    //             <Slider moveThreshold={.2} style={{ width: "1000px", overflowX: 'visible' }}>
                    //                 {teamMembers.map((member, index) => <Slide index={index}> <MobileCaroussel index={index} member={member} /></Slide>)}
                    //             </Slider>
                    //             <div>
                    //                 <CarrousselNavigation totalSlide={teamMembers.length} />
                    //             </div>
                    //         </CarouselProvider>
                    //     </div>
                    // </div>
                    // :
                }
            </div>
        </div >
    )
}

/*
 <button onClick={onClickPrev}><ArrowRight className={styles.arrowLeft} /></button>
                <div className={styles.stepNumber}>{`${currentIndex + 1} / 3`}</div>
                <button onClick={onClickNext}><ArrowRight /></button>

*/

Team.displayName = "Team"

export default Team
