import React from "react"
import styles from './Identity.module.css'
import Lottie from 'react-lottie'
import developpeurAnim from '../../assets/developpeur.json'
import diamantAnim from '../../assets/diamant.json'
import laPourVousAnim from '../../assets/lapourvous.json'
import designerAnim from '../../assets/Designer.json'

import CTAButton from "../../components/CTAButton/CTAButton";
import SectionTitle from "../../components/SectionTitle/SectionTitle";


const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const IdentitityBlock = ({ animationData, titleRegular, titleSeo, children }) =>
    <>
        <div className={styles.lottie}>
            <Lottie options={{ ...defaultOptions, animationData }} />
        </div>
        <div className={styles.text}>
            <h3>
                {titleRegular}<br />
                <strong className="extended">{titleSeo}</strong>
            </h3>
            <p className={styles.identityDesc}>
                {children}
            </p>
        </div>
    </>

const Identity = () => {

    return (
        <div className={styles.layout}>
            <div className={`section`} >
                <div className={styles.head}>
                    <SectionTitle title="Nos valeurs" />
                </div>
                <h2 className={styles.topLine}>
                    <strong>Agence digitale </strong> pluridisciplinaire visant la<strong> perfection </strong>
                    pour chaque projet
                </h2>
                <IdentitityBlock animationData={designerAnim} titleRegular={"Designers"} titleSeo={"Innovants"}>
                    {
                        "Pour nous, le design ne représente pas qu’un idéal esthétique ; c'est aussi une façon de voir, de penser et de servir. \
                        Nous utilisons les différents outils du design dans un seul but : vous accompagner dans l’élaboration d’une solution visant la bonne audience et à \
                        vous engager avec elle. \
                        Nous vous aidons à construire une solution efficace et ancrée dans un monde numérique en perpétuelle évolution"
                    }
                </IdentitityBlock>
                <IdentitityBlock animationData={developpeurAnim} titleRegular={"Développeurs"} titleSeo={"Créatifs"}>
                    {
                        "à La Forge, nous pensons que vous êtes plus qu’un simple client. Vous êtes un membre à part entière de l’équipe. \
                        Devenez acteur. Nous mettons en place tous les outils nécessaires afin de vous offrir transparence et feedbacks à chaque étape du process de création. \
                        Forgeons ensemble un produit irréprochable."
                    }
                </IdentitityBlock>
                <IdentitityBlock animationData={diamantAnim} titleRegular={"Artisans"} titleSeo={"2.o"}>
                    {
                        "Le développement n’est pas qu’une compétence technique. C’est aussi des centaines d’heures de veille à la recherche de solutions créatives \
                        afin de proposer aux utilisateurs une expérience fluide pour tout usage. Être développeur, c’est être force de proposition afin de \
                        transformer votre idée en solution durable, \
                        de la manière la plus optimisée et en tenant compte des nouveaux enjeux technologiques."
                    }
                </IdentitityBlock>
                <IdentitityBlock animationData={laPourVousAnim} titleRegular={"Là pour"} titleSeo={"Vous"}>
                    {
                        "Vous êtes plus qu’un simple client, un membre à part entière de notre équipe. \
                        Devenez acteur. Nous mettons en place tous les outils nécessaires afin de vous offrir conseils et transparence à chaque étape. \
                        Forgeons ensemble un produit irréprochable."
                    }
                    <CTAButton className={styles.button} title={"NOUS CONTACTER"} />
                </IdentitityBlock>
            </div>
        </div>
    )
}

Identity.displayName = "Identity"

export default Identity
