import React from "react"
import c from "classnames"

import styles from './SectionTitle.module.css'

const SectionTitle = ({ title, darkBackground, className }) => {
    return (
        <div className={className}>
            <div className={c(styles.sectionTitle, darkBackground && styles.darkBackgroundText)}>
                <div className={c(styles.line, darkBackground && styles.darkBackground)} />
                <div>{title}</div>
            </div>
        </div>
    )
}

export default SectionTitle
