import React, { useRef, useEffect } from "react"

import styles from './Workflow.module.css'

import CTAButton from '../../components/CTAButton/CTAButton'
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import Carousel from "../../components/Carousel/Carousel";



const steps = [
    {
        key: "découvrir",
        renderTitleComponent: () => (<h3 >Découvrir <strong>votre</strong> pépite</h3>),
        description: `Rencontrons-nous autour d’un café,
        échangeons autour de vos idées, raffinons votre projet
         afin d’esquisser le produit de vos envies.`
    },
    {
        key: "forger",
        renderTitleComponent: () => (<h3 >Forger <strong>votre</strong> produit</h3>),
        description: `Nous travaillons au corps la matière
        première de votre produit et nous donnons forme à votre idée.`
    },
    {
        key: "projet",
        renderTitleComponent: () => (<h3 ><strong>Votre </strong> projet solide comme l’acier</h3>),
        description: `La qualité avant tout ! 
        Nous finalisons la création de votre produit et nous mettons en place 
        tous les éléments nécessaires à la tenue de votre produit dans la durée.`
    }
]

const Workflow = () => {
    const titleRef = useRef()

    const handleResize = () => {
        if (titleRef.current) {
            const { left } = titleRef.current.getBoundingClientRect()
            document.documentElement.style.setProperty('--workflow-left', left + "px");
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return (() => window.removeEventListener('resize', handleResize))
    }, [])


    return (
        <div className={styles.background}>
            <div className={`section ${styles.layout}`}>
                <div ref={titleRef} className={styles.topPart} >
                    <SectionTitle title="NOTRE WORKFLOW" className={styles.sectionName} />
                    <h2>
                        <strong className="extended">Vous </strong>
                        êtes au centre de notre attention
                </h2>
                </div>
            </div>
            <Carousel
                fade
                containerClassName={styles.horizontalScrollContainer}
                renderOverlay={() => (
                    <div className={styles.lineWrapper}>
                        <div className={styles.line} />
                        <div className={styles.circleOutline}></div>
                        <div className={styles.blueCircle}></div>
                    </div>
                )}
                data={steps}
                renderItem={(item, index) => (
                    <div className={styles.step}>
                        <div className={styles.ball}></div>
                        {item.renderTitleComponent()}
                        <p className={styles.description}>{item.description}</p>
                    </div>
                )}
                keyExtractor={item => item.key}
            />
            <div className={styles.ctaContainer} >
                <CTAButton title="BOIRE UN CAFÉ" onClick={() => console.warn("OK")} />
            </div>
        </div>
    )
}

export default Workflow
