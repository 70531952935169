import React, { useRef, useEffect } from "react"
import c from 'classnames'
import styles from './Input.module.css'

const Input = ({ className, label, error, disabled, ...inputProps }) => {
    return (
        <div className={c(styles.container, className, disabled && styles.disabled)}>
            <input id={label} className={c(styles.input, error && styles.error)} placeholder={label} {...inputProps} disabled={disabled} />
            <label htmlFor={label} className={c(styles.label, error && styles.labelError)} data-content={label}>
                {label}
            </label>
        </div>

    )
}

export default Input
