import React, { useRef, useEffect } from "react"
import c from 'classnames'

import styles from './TextArea.module.css'

const TextArea = ({ label, error, disabled, ...inputProps }) => {

    const textAreaRef = useRef()

    useEffect(() => {
        if (textAreaRef.current) {
            const inputHandler = (event) => {
                if (event.target) {
                    // event.target.style.height = 'auto';
                    event.target.style.height = event.target.scrollHeight + offset + 'px';
                }
            }

            const element = textAreaRef.current
            element.style.boxSizing = 'border-box';
            var offset = element.offsetHeight - element.clientHeight;
            element.addEventListener('input', inputHandler);
            return (() => { element.removeEventListener('input', inputHandler) })
        }
    }, [textAreaRef])

    return (
        <div className={c(styles.container, disabled && styles.disabled)}>
            <textarea spellcheck="false" ref={textAreaRef} id={label} className={c(styles.input, error && styles.error)} placeholder={label} {...inputProps} />
            <label htmlFor={label} className={c(styles.label, error && styles.labelError)} data-content={label}>
                {label}
            </label>
        </div>

    )
}

export default TextArea
