import React, { useState, useEffect } from "react"
import { CSSTransition } from 'react-transition-group'
import c from "classnames"
import styles from './ChangingText.module.css'

const lines = [
    { text: "DESIGNERS INNOVANTS" },
    { text:"DÉVELOPPEURS CRÉATIFS"},
    { text:"ARTISANS 2.0"},
    { text:"LÀ POUR VOUS"},
    { text:"LA FORGE", className: styles.laForge}
]

const ChangingText = ({ onLaForgeAppear }) => {

    const [forEntering, setForEntering] = useState(null);
    const [forExit, setForExit] = useState(null);

    const onEntered = () => {
        setForExit(forEntering)
        setForEntering(null)
        setTimeout(() => makeSwitch(), 1400)
    }

    const makeSwitch = () => {
        if (forEntering < lines.length - 1) {
            if (forEntering + 1 === lines.length - 1) {
                onLaForgeAppear()
            }
            setForEntering(forEntering + 1)
        }
    }

    useEffect(() => {
        setForEntering(0)
    }, [])

    return (
        <div className={styles.wrapper}>
            <CSSTransition
                in={forEntering !== null}
                timeout={800}
                classNames={{
                    enter: styles.forEnteringEnter,
                    enterActive: styles.forEnteringEnterActive,
                }}
                onEntered={onEntered}
            >
                <h1 className={c(styles.line, lines[forEntering]?.className && lines[forEntering].className)}>
                    {lines[forEntering]?.text}
                </h1>
            </CSSTransition>
            <CSSTransition
                in={forEntering === null}
                timeout={800}
                classNames={{
                    exit: styles.forExitExit,
                    exitActive: styles.forExitExitActive,
                }}
            >
                <h1 className={c(styles.line, lines[forExit]?.className && lines[forExit].className)}>
                    {lines[forExit]?.text}
                </h1>
            </CSSTransition>
        </div>

    )
}

export default ChangingText
