import React from "react"
import { navigate } from 'gatsby-link'
import c from 'classnames'

import styles from './ContactForm.module.css'
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import Input from "./Input"
import TextArea from "./TextArea"

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhonenumber(str) {
    if (str.length < 4)
        return false
    for (let i = 0; i < str.length; i += 1) {
        const char = str.charAt(i)
        if (!((char >= '0' && char <= '9') || char === '+'))
            return false
    }
    return true
}

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ContactForm = () => {
    const [state, setState] = React.useState({})
    const [errors, setErrors] = React.useState({
        nom: true,
        email: true,
        tel: true,
        message: true,
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        if (checkErrors())
            return

        const form = e.target

        const body = encode({
            'form-name': form.getAttribute('name'),
            ...state,
        })

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body,
        })
            .then(() => navigate('/thanks'))
            .catch((error) => alert(error))
    }

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        onBlur(e)
    }

    const setError = (field, hasError) => {
        setErrors({ ...errors, [field]: hasError })
    }

    const checkErrors = () => {
        let hasError = false
        Object.values(errors).forEach(f => {
            if (f !== false)
                hasError = true
        })
        return hasError
    }

    const checkError = (field, value) => {
        if (field === 'nom')
            setError(field, value.length === 0)
        if (field === 'email')
            setError(field, !validateEmail(value))
        if (field === 'tel')
            setError(field, !validatePhonenumber(value))
        if (field === 'message')
            setError(field, value.length === 0)
    }

    const onBlur = (e) => {
        checkError(e.target.name, e.target.value)
    }

    // console.log("errors", errors)
    return (
        <div className={styles.background}>
            <div className={`section ${styles.layout}`} >
                <div className={styles.topPart} id="contact">
                    <SectionTitle title="CONTACT" darkBackground />
                    <h2>{"Vous avez une idée? Nous ne sommes qu’à un clic."}</h2>
                </div>
                <form
                    name="contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    className={c(styles.form)}
                >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                        <label>
                            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                        </label>
                    </p>
                    {/* Bot field end */}
                    <Input label="NOM" type="text" name="nom" onChange={handleChange} onBlur={onBlur} />
                    <div className={styles.middleFields}>
                        <Input label="MAIL" type="email" name="email" onChange={handleChange} onBlur={onBlur} />
                        <Input label="TÉLÉPHONE" type="tel" name="tel" onChange={handleChange} onBlur={onBlur} />
                    </div>
                    {/* <Input label="VOTRE PROJET" textarea name="message" onChange={handleChange} /> */}
                    <TextArea label="VOTRE PROJET" name="message" onChange={handleChange} onBlur={onBlur} />
                    <div className={styles.submitContainer}>
                        <button aria-label="ENVOYER" type="submit" className={styles.submitButton} disabled={!Object.values(errors).every(error => error === false)}>ENVOYER</button>
                    </div>
                </form>
                <div className={styles.callUs}>
                    Ou appelez-nous simplement <br />
                    <strong>+33 (0)6 11 11 11 11</strong>

                </div>
            </div>
        </div>
    )
}

export default ContactForm
