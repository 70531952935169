import React, { useState } from "react"
import { CSSTransition } from 'react-transition-group'

import styles from './Welcome.module.css'
import Wave from './Wave'
import ChangingText from "./ChangingText"
import CTAButton from "../../components/CTAButton/CTAButton"
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import Lottie from 'react-lottie'
import ScrollAnimation from '../../assets/scroll.json'


const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    },
    animationData: ScrollAnimation
};

const Welcome = () => {

    const [showCTA, setShowCTA] = useState(false)

    const onLaForgeAppear = () => {
        setShowCTA(true)
    }

    return (
        <div className={`${styles.layout}`} style={{ position: 'relative' }}>
            <div className={`section`} >
                <Wave />
                <div className={styles.mainText}>
                    <div className={styles.logo} />
                    <SectionTitle title="NOUS SOMMES" darkBackground />
                    <ChangingText onLaForgeAppear={onLaForgeAppear} />
                    <div>
                        <CSSTransition
                            in={showCTA}
                            timeout={800}
                            classNames={{
                                enter: styles.forEnteringEnter,
                                enterActive: styles.forEnteringEnterActive,
                            }}
                            unmountOnExit
                        >
                            <CTAButton title="NOUS RENCONTRER" onClick={() => console.warn("OK")} className={styles.cta} />
                        </CSSTransition>

                    </div>
                </div>
            </div>
            <div className={styles.lottie}>
                <Lottie options={defaultOptions} />
            </div>
        </div>
    )
}

export default Welcome
