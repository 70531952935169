import React, { useState, useEffect } from "react"
import styles from './Wave.module.css'
import c from 'classnames'

const Wave = () => {

    const [showWave, setShowWave] = useState(true)

    const onResize = () => {
        if (showWave)
            setShowWave(false)
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return (() => window.removeEventListener('resize', onResize))
    }, [])

    useEffect(() => {
        if (!showWave)
            setTimeout(() => {
                setShowWave(true)
            }, 500)
    }, [showWave])

    return (
        <div className={styles.wrapper}>
            <div className={c(styles.waveContainer, !showWave && styles.hide)}>
                <div className={styles.wave} />
                <div className={styles.wave} />
            </div>
        </div>


    )
}

export default Wave
