import React, { useRef, useState, useEffect } from "react"
import c from "classnames";
import smoothscroll from './smoothScrollPolyfill'

import styles from './Carousel.module.css'

import ArrowRight from '../../assets/arrow_right.inline.svg'

smoothscroll.polyfill();


const isSafari = typeof navigator !== "undefined" && navigator.userAgent.indexOf("Safari") != -1

// Setup isScrolling variable
let isScrolling;



const calculateClosest = (currentScroll, itemWidth, length) => {
    let closest = { index: 0, distance: Math.abs(currentScroll), offset: currentScroll }
    for (let i = 0; i < length; i += 1) {
        const offset = currentScroll - (i * itemWidth)
        const distance = Math.abs(offset)
        if (distance < closest.distance)
            closest = { index: i, offset, distance }
    }
    return closest
}

const Carousel = ({ renderOverlay, data, renderItem, containerClassName, fade, keyExtractor }) => {
    const [downAt, setDownAt] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const scrollRef = useRef()
    const itemRef = useRef()

    const handleMouseLeave = (e) => {
        onMouseUp()
    }

    useEffect(() => {
        document.body.addEventListener('mouseleave', handleMouseLeave)
        setTimeout(() => { onMouseUp() }, 500)
        return (() => document.body.removeEventListener('mouseleave', handleMouseLeave))
    }, [])

    const getCurrentClosestItem = () => {
        if (!scrollRef.current || !itemRef.current)
            return
        const itemWidth = Math.floor(itemRef.current.getBoundingClientRect().width)
        const currentScroll = Math.floor(scrollRef.current.scrollLeft)
        return calculateClosest(currentScroll, itemWidth, data.length)
    }

    const scrollToIndex = (index) => {
        if (index >= data.length || index < 0)
            return
        if (!scrollRef.current || !itemRef.current)
            return
        const itemWidth = Math.floor(itemRef.current.getBoundingClientRect().width)
        scrollRef.current.scrollTo({ left: (itemWidth * index) + 1, behavior: 'smooth' })
    }

    const onClickPrev = () => {
        scrollToIndex(currentIndex - 1)
    }

    const onClickNext = () => {
        scrollToIndex(currentIndex + 1)
    }

    const onScroll = (e) => {
        // console.log("onScroll", e.target.scrollLeft)

        // Clear our timeout throughout the scroll
        clearTimeout(isScrolling);

        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(function () {

            // Run the callback
            console.log('Scrolling has stopped.');

        }, 66);

        setScrollLeft(e.target.scrollLeft)

        const nearestItem = getCurrentClosestItem()
        setCurrentIndex(nearestItem.index)
    }

    const onTouchStart = (e) => {
        if (!scrollRef.current)
            return
        setDownAt({
            x: e.touches[0].clientX,
            container: scrollRef.current.scrollLeft
        })

    }

    const onTouchMove = (e) => {
        e.preventDefault();
        if (downAt && scrollRef.current) {
            const diff = e.touches[0].clientX - downAt.x
            const scrollTo = downAt.container - diff
            scrollRef.current.scrollTo({ left: scrollTo })
        }
    }

    const onTouchEnd = (e) => {
        onMouseUp()
    }

    const getOpacityForIndex = (index) => {
        if (!itemRef.current || !scrollRef.current) {
            if (index === 0)
                return 1
            else
                return .3
        }
        const itemWidth = Math.floor(itemRef.current.getBoundingClientRect().width)
        let diff = ((itemWidth * index) - scrollLeft)
        diff = diff >= 0 ? diff : (diff * -1)
        let diffRatioCapped = diff / itemWidth
        diffRatioCapped = diffRatioCapped > 1 ? 1 : diffRatioCapped
        let opacity = 1.3 - diffRatioCapped
        opacity = opacity > 1 ? 1 : opacity
        return opacity.toFixed(2)
    }

    const getOpacities = () => {
        // const isClientSideRender = typeof window !== "undefined"
        // const isDesktop = isClientSideRender ? window.matchMedia("(min-width: 768px)").matches : false

        const length = data.length
        const opacities = new Array(length);
        for (let i = 0; i < length; i += 1) {
            opacities[i] = getOpacityForIndex(i)
        }
        return opacities
    }

    const opacities = getOpacities()

    // console.log("opactities", opacities)
    const onMouseDown = (e) => {
        if (!scrollRef.current)
            return

        setDownAt({
            x: e.pageX,
            container: scrollRef.current.scrollLeft
        })
    }


    const onMouseUp = () => {
        const scrollToNearest = () => {
            const nearest = getCurrentClosestItem()
            scrollToIndex(nearest.index)
        }

        if (isSafari)
            scrollToNearest()
        else
            setTimeout(() => scrollToNearest(), 100)

        setDownAt(null)
    }

    const onMouseMove = (e) => {
        if (downAt && scrollRef.current) {
            const diff = e.pageX - downAt.x
            const scrollTo = downAt.container - diff
            scrollRef.current.scrollTo({ left: scrollTo })
        }
    }

    return (
        <>
            <div
                className={c(styles.horizontalScrollContainer, containerClassName, !downAt && styles.snap)}
                ref={scrollRef}
                onScroll={onScroll}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                {renderOverlay()}
                {data.map((item, index) => {
                    return (
                        <div
                            ref={index === 0 ? itemRef : null}
                            className={c(styles.item, !downAt && styles.snapItem)}
                            style={{ opacity: fade ? opacities[index] : 1 }}
                            key={keyExtractor(item)}
                        >
                            {renderItem(item, index)}
                        </div>
                    )
                })}
                <div className={styles.whiteSpace}></div>
            </div>
            <div className={styles.arrowsContainer}>
                <button aria-label="previous" onClick={onClickPrev}><ArrowRight className={styles.arrowLeft} /></button>
                <div className={styles.stepNumber}>{`${currentIndex + 1} / 3`}</div>
                <button aria-label="next" onClick={onClickNext}><ArrowRight /></button>
            </div>
        </>
    )
}

export default Carousel
