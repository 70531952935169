import React from "react"
import "normalize.css"
import { Helmet } from "react-helmet"

import "../styles"
import Welcome from '../sections/Welcome/Welcome'
import Identity from "../sections/Identity/Identity"
import Skills from '../sections/Skills/Skills'
import Workflow from "../sections/Workflow/Workflow"
import ContactForm from '../sections/ContactForm/ContactForm'
import Team from "../sections/Team/Team"

export default () => <div >
    <Helmet>
        <title>La Forge</title>
        <html lang="fr" />
    </Helmet>
    <Welcome />
    <Identity />
    <Workflow />
    <Skills />
    <Team />
    <ContactForm />
</div>
