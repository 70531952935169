import React from "react"
import styles from './Team.module.css'
import 'pure-react-carousel/dist/react-carousel.es.css';
import { WithStore } from 'pure-react-carousel';



const MobileMember = ({ member: { name, skill }, index, currentSlide }) => {

    return <div
        style={{
            width: '100%',
            transition: 'all .3s ease',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            opacity: currentSlide === index ? '1' : '.3'
        }}
    >
        <div className={styles.thomas} />
        <div style={{
            transition: 'all .3s ease',
            opacity: currentSlide === index ? '1' : '.3',
            paddingRight: '70px'
        }}>
            <h3>
                {name}
            </h3>
            <div className={styles.skillContainer}>
                <div className={styles.line_full} />
                <div className={styles.skill} >
                    {skill}
                </div>
            </div>

        </div>
    </div>
}


export default WithStore(MobileMember, state => ({
    currentSlide: state.currentSlide,
}));