import React from "react"
import styles from './Team.module.css'
import SectionTitle from "../../components/SectionTitle/SectionTitle"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { WithStore } from 'pure-react-carousel';
import ArrowRight from '../../assets/arrow_right.inline.svg'



const MobileMember = ({ currentSlide, totalSlide }) => {

    return <div
        style={{
            width: '100%',
            display: "flex",
            alignItems: 'space-between',
            justifyContent: 'center',
            marginTop: '56px'
        }}>

        <ButtonBack style={{ backgroundColor: 'rgba(0,0,0,0)', border: '0', outlineStyle: 'none' }} ><ArrowRight style={{ transform: 'rotate(180deg)' }} /></ButtonBack>
        <div >{`${currentSlide + 1} / ${totalSlide}`}</div>
        <ButtonNext style={{ backgroundColor: 'rgba(0,0,0,0)', border: '0', outlineStyle: 'none' }}><ArrowRight /></ButtonNext>
    </div>


    // <div className={styles.member}>
    //     <h3>
    //         {name}
    //     </h3>
    //     <div className={styles.skillContainer}>
    //         <div className={styles.line} />
    //         <div className={styles.skill} >
    //             {skill}
    //         </div>
    //     </div>
    // </div>
}


export default WithStore(MobileMember, state => ({
    currentSlide: state.currentSlide,
}));