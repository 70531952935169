import React from "react"
import styles from './Skills.module.css'
import SectionTitle from "../../components/SectionTitle/SectionTitle"

const Skills = () => {

    return (
        <div className={`${styles.background}`}>
            <div className={`section ${styles.layout}`}>
                 <div className={styles.topPart}>
                    <SectionTitle title="NOS COMPÉTENCES" darkBackground className={styles.sectionName} />
                    <h2 className={styles.title}>Notre expertise, nos talents</h2>
                </div>
               <div className={`${styles.category} ${styles.one}`}>
                    <div className={styles.categoryTitle}>WEB</div>
                    <div className={styles.categoryItem}>Back end</div>
                    <div className={styles.categoryItem}>Front end</div>
                    <div className={styles.categoryItem}>Plateforme métier</div>
                </div>
               <div className={`${styles.category} ${styles.two}`}>
                    <div className={styles.categoryTitle}>APPS</div>
                    <div className={styles.categoryItem}>Bureau (Windows Mac Linux)</div>
                    <div className={styles.categoryItem}>Mobile (iOS Android)</div>
                    <div className={styles.categoryItem}>Cross-platform</div>
                </div>
                 <div className={`${styles.category} ${styles.three}`}>
                    <div className={styles.categoryTitle}>DESIGN</div>
                    <div className={styles.categoryItem}>Recherche utilisateurs</div>
                    <div className={styles.categoryItem}>Wireframing</div>
                    <div className={styles.categoryItem}>UX/UI Design</div>
                    <div className={styles.categoryItem}>Motion graphics</div>
                </div>
                <div className={`${styles.category} ${styles.four}`}>
                    <div className={styles.categoryTitle}>CONSULTING</div>
                    <div className={styles.categoryItem}>Ergonomie</div>
                    <div className={styles.categoryItem}>Formation</div>
                    <div className={styles.categoryItem}>Intégration logicielle</div>
                    <div className={styles.categoryItem}>Sécurité</div>
                </div> 
            </div>
        </div>
    )
}

export default Skills
